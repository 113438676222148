import React from "react";
import { withRouter } from "react-router-dom";
import CursoService from "../app/service/cursoService";
import Iframe from "react-iframe";
import * as message from "../components/toastr";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { AuthContext } from "../main/provedorAutenticacao";

class PlayerRevisao extends React.Component {
  constructor() {
    super();
    this.service = new CursoService();
  }

  state = {
    urlAula: "",
    curso: null,
  };

  async componentDidMount() {
    this.verificaBloqueioCursoApostila();
    const params = this.props.match.params;

    //Verifica se já teve acesso
    await this.service
      .aulaJaRevisada(params.idcurso, params.aula)
      .then((response) => {      
        if(response.data){
            message.mensagemAlert("Você já realizou a revisão!")
            this.props.history.push("/reverAula");
        }
    });

    this.service
      .gravarAcessoRevisao(params.idcurso, params.aula)
      .then(() => {
        console.log("Acesso Registrado");
    });

    if (params) {
      this.service
        .buscarCursoPadrao(params.codigoCurso)
        .then((response) => {
          if (response.data === "Nenhum curso encontrado!") {
            message.mensagemErro("Curso não encontrado!");
          } else {
            const url = `${response.data.caminho}/aula${params.aula}/index.html`;

            this.setState({ urlAula: url, curso: response.data });
          }
        })
        .catch((erro) => {
          console.error(erro.response.data);
          message.mensagemErro("Erro ao buscar Curso!");
        });
    }
  }

  voltar = () => {
    this.props.history.push("/reverAula");
  };

  verificaBloqueioCursoApostila = () => {
    const params = this.props.match.params;

    this.service
      .bloqueioDeApostila(params.idcurso)
      .then((response) => {
        if (response.data === true) {
          message.mensagemAlert(
            "Você precisa entrar em contato com a secretaria e solicitar a apostila."
          );
          this.props.history.push("/home");
        }
      })
      .catch((erro) => {
        console.error("Erro ao verificar bloqueioCursoApostila!");
      });
  };

  render() {
    return (
      <>
        <Iframe
          url={this.state.urlAula}
          width="100%"
          height="100%"
          id="frameAula"
          name="frameAula"
          allowFullScreen="true"
          className="myClassname"
          display="initial"
          position="absolute"
        />
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "absolute", bottom: 1 / 2, right: 3, height: 10 }}
          icon={<ExitToAppIcon />}
        >
          <SpeedDialAction
            key={"Voltar para Home"}
            icon={<MeetingRoomIcon />}
            tooltipTitle={"Voltar"}
            onClick={this.voltar}
          />
        </SpeedDial>
      </>
    );
  }
}

PlayerRevisao.contextType = AuthContext;

export default withRouter(PlayerRevisao);
