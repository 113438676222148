import React from "react";
import { Container, Row, Card, Button, Form } from "react-bootstrap";
import LoginService from "../app/service/loginService";
import AuthService from "../app/service/authService";
import { AuthContext } from "../main/provedorAutenticacao";
import * as message from "../components/toastr";
import { withRouter } from "react-router-dom";
import ModalMensalidadeAtra from "./modal/modalMensalidadeAtra";
import "./css/login.css";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import ParametroService from "../app/service/parametroService";
import Notificacao from "../components/notificacao";



class Login extends React.Component {
  constructor() {
    super();
    this.service = new LoginService();
    this.authservice = new AuthService();
    this.serviceParametro = new ParametroService();
  }

  state = {
    codigo: "",
    senha: "",
    modalInstrutorShow: false,
    carregando: false,
    avisoAtualizacao: true,
  };

  componentDidMount() {
    const params = this.props.match.params;

    var tablet = window.matchMedia("(max-width: 800px)");
    var celular = window.matchMedia("(max-width: 400px)");

    if (params.url !== undefined) {
      let imgs = null;

      this.serviceParametro
        .planoDeFundo(params.url)
        .then((response) => {
          imgs = response.data;

          if (imgs !== null) {
            var div = document.getElementById("fundo");
            if (celular.matches) {
              div.style.backgroundImage = `url(${imgs.imgFundo400})`;
            } else if (tablet.matches) {
              div.style.backgroundImage = `url(${imgs.imgFundo800})`;
            } else {
              div.style.backgroundImage = `url(${imgs.imgFundo1080})`;
            }
          }
        })
        .catch((erro) => {
          console.error(erro.response);
        });
    }

    if (
      !document.fullscreenElement && // alternative standard method
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      // current working methods
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  entrar = (event) => {
    this.setState({ carregando: true });

    this.service
      .autenticar({
        codigo: this.state.codigo,
        senha: this.state.senha,
      })
      .then((response) => {
        this.setState({ carregando: false });
        message.mensagemSucesso("Login realizado com sucesso!");
        this.context.iniciarSessao(response.data);
        this.props.history.push("/home");
      })
      .catch((erro) => {
        this.setState({ carregando: false });
        if (erro.response.data === "mensalidades em atraso") {
          this.setState({ modalInstrutorShow: true });
        } else if (erro.response.data === "Sem horário disponivel!") {
          message.mensagemAlert("Sem horário disponível!");
        } else if (erro.code === "ERR_NETWORK") {
          message.mensagemErro("Api não encontrada!");
        } else if (erro.response.data === "Apostila não entregue!") {
          message.mensagemAlert("Apostila não entregue!");
        } else {
          message.mensagemErro(erro.response.data);
        }
      });
    event.preventDefault();
  };

  irParaHome = () => {
    this.props.history.push("/home");
  };

  mudarCor = () => {
    document.body.style.backgroundImage = "url(./frequencia.jpg)";
  };

  render() {
    return (
      <div className="fundo" id="fundo">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.carregando}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container fluid="md">
          <Row>
            <Card
              className="col-md-6 col-sm-12 mx-auto mt-5"
              style={{ boxShadow: "5px 5px 5px black" }}
            >
              <Card.Header
                style={{
                  fontSize: 30,
                  fontFamily: "Rubik",
                  textAlign: "center",
                }}
                as="h5"
              >
                LOGIN
              </Card.Header>
              <Card.Body>
                <Form className="mt-3" onSubmit={this.entrar}>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicCodigo"
                    required
                  >
                    <Form.Label>Código Matrícula</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Código"
                      onChange={(e) =>
                        this.setState({ codigo: e.target.value })
                      }
                      value={this.state.codigo}
                      autoFocus
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Senha"
                      onChange={(e) => this.setState({ senha: e.target.value })}
                      value={this.state.senha}
                      required
                    />
                  </Form.Group>

                  <Button className="mb-2" variant="primary" type="submit">
                    Entrar
                  </Button>

                  <br />
                  <span style={{ fontSize: 11 }}>Versão: 0.3.3</span>
                </Form>
              </Card.Body>
            </Card>
          </Row>
        </Container>
        <ModalMensalidadeAtra
          show={this.state.modalInstrutorShow}
          onHide={() => this.setState({ modalInstrutorShow: false })}
          codigo={this.state.codigo}
          senha={this.state.senha}
          irParaHome={() => {
            this.irParaHome();
          }}
        />
        <Notificacao />           
      </div>
    );
  }
}

Login.contextType = AuthContext;

export default withRouter(Login);
